import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem, Form, Button, Spinner } from 'react-bootstrap';
import './ChatHistoryStyle.css';
import { fetchWithSessionToken } from './session_http_helper';

const ChatbotNotifications = ({ shop }) => {
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [emails, setEmails] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchNotificationSettings = async () => {
            try {
                const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/live_chat/notifications/${shop}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch notification settings');
                }

                const data = await response.json();
                setPhoneNumbers(data.phoneNumbers || []);
                setEmails(data.emails || []);
            } catch (error) {
                console.error('Failed to fetch notification settings:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchNotificationSettings();
    }, [shop]);

    const handleAddPhoneNumber = () => {
        setPhoneNumbers([...phoneNumbers, '']);
    };

    const handleAddEmail = () => {
        setEmails([...emails, '']);
    };

    const handlePhoneNumberChange = (index, value) => {
        const newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers[index] = value;
        setPhoneNumbers(newPhoneNumbers);
    };

    const handleEmailChange = (index, value) => {
        const newEmails = [...emails];
        newEmails[index] = value;
        setEmails(newEmails);
    };

    const handleSave = async () => {
        setIsSaving(true);
        
        const notificationData = {
            phoneNumbers,
            emails
        };

        try {
            const response = await fetchWithSessionToken(`https://brella-chatbot-64b531dcb02f.herokuapp.com/live_chat/notifications/${shop}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(notificationData)
            });
            
            if (!response.ok) {
                throw new Error('Failed to save notifications');
            }

            const data = await response.json();
            console.log('Save successful:', data);
        } catch (error) {
            console.error('Failed to save notifications:', error);
        } finally {
            setIsSaving(false);
        }
    };

    if (isLoading) {
        return (
            <Container className="pt-5 pb-5 text-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    return (
        <Container className="pt-5 pb-5">
            <Row>
                <Col className="text-right">
                    <Button variant="primary" onClick={handleSave} disabled={isSaving}>
                        {isSaving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save'}
                    </Button>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <h4>Phone Numbers</h4>
                    <ListGroup>
                        {phoneNumbers.map((phoneNumber, index) => (
                            <ListGroupItem key={index}>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter phone number"
                                    value={phoneNumber}
                                    onChange={(e) => handlePhoneNumberChange(index, e.target.value)}
                                />
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                    <Button className="mt-3" onClick={handleAddPhoneNumber}>Add Phone Number</Button>
                </Col>
                <Col>
                    <h4>Emails</h4>
                    <ListGroup>
                        {emails.map((email, index) => (
                            <ListGroupItem key={index}>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email address"
                                    value={email}
                                    onChange={(e) => handleEmailChange(index, e.target.value)}
                                />
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                    <Button className="mt-3" onClick={handleAddEmail}>Add Email</Button>
                </Col>
            </Row>
        </Container>
    );
};

export default ChatbotNotifications;
