import React, { useState, useEffect, useRef } from 'react';
import './ChatStyle.css'
import { Form, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import NavBar from './NavbarMenu';
  
export default function Features() {
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [localCompanyID, setLocalCompanyID] = useState('test-brella-ai.myshopify.com');
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [formData, setFormData] = useState({
        localCompanyID: '',
        welcomeMessage: '',
        welcomeMessageOptions: '',
        contentPrompt: ''
    });

    const [openAIConfig, setOpenAIConfig] = useState({
        chat_container_width: '',
        chat_container_height: '',
        chat_toggle_button_width: '',
        chat_toggle_button_height: '',
        chat_toggle_button_color: '',
        chat_toggle_button_color_hover: '',
        chat_header_background_color: '',
        chat_input_button_color: '',
        chat_input_button_background: '',
        dot_background_color: '',
        user_message_color: '',
        user_message_background_color: '',
        server_message_color: '',
        server_message_background_color: ''
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeConfig = (e) => {
        const { name, value } = e.target;
        setOpenAIConfig(prevState => {
            const updatedConfig = {
                ...prevState,
                [name]: value,
            };
    
            // Set CSS variables for styling
            for (const [key, value] of Object.entries(updatedConfig)) {
                const cssVarName = `--${key.replace(/_/g, '-')}`;
                document.documentElement.style.setProperty(cssVarName, value || '');
            }
    
            return updatedConfig;
        });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setSaveSuccess(false);

        const welcomeMessageOptionsList = String(formData.welcomeMessageOptions).split(',').map(option => option.trim());

        const updatedFormData = {
            ...formData,
            welcomeMessageOptions: welcomeMessageOptionsList,
            openAIConfig: { ...openAIConfig }
        };

        try {
            const response = await fetch(`https://brella-chatbot-64b531dcb02f.herokuapp.com/ai/chat/company?companyId=${localCompanyID}`, {
            // const response = await fetch(`http://localhost:8080/ai/chat/company?companyId=${localCompanyID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedFormData),
            });
            setSaveSuccess(true);
        } catch (error) {
            console.error('Error posting data:', error);
        }
            
        setIsSaving(false);

        fetchWelcomeMessage(localCompanyID);
    };

    // Fetch welcome message function
    const fetchWelcomeMessage = async (localCompanyID) => {

        try {
            const response = await fetch(`https://brella-chatbot-64b531dcb02f.herokuapp.com/ai/chat/welcome?companyId=${localCompanyID}`);
            // const response = await fetch(`http://localhost:8080/ai/chat/welcome?companyId=${localCompanyID}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const { welcomeMessage, welcomeMessageOptions, openAIConfig } = data;
    
            // Update formData state
            setFormData(prevFormData => ({
                ...prevFormData,
                companyId: localCompanyID,
                welcomeMessage: welcomeMessage,
                welcomeMessageOptions: welcomeMessageOptions,
                contentPrompt: data.contentPrompt // Assuming you want to keep the existing content prompt
            }));
    
            // Update openAIConfig state
            setOpenAIConfig({
                chat_container_width: openAIConfig?.chat_container_width || '400px',
                chat_container_height: openAIConfig?.chat_container_height || '500px',
                chat_toggle_button_width: openAIConfig?.chat_toggle_button_width || '50px',
                chat_toggle_button_height: openAIConfig?.chat_toggle_button_height || '50px',
                chat_toggle_button_color: openAIConfig?.chat_toggle_button_color || '#5a67d8',
                chat_toggle_button_color_hover: openAIConfig?.chat_toggle_button_color_hover || '#4c51bf',
                chat_header_background_color: openAIConfig?.chat_header_background_color || '#ffffff',
                chat_input_button_color: openAIConfig?.chat_input_button_color || '#5a67d8',
                chat_input_button_background: openAIConfig?.chat_input_button_background || '#f7f7f7',
                dot_background_color: openAIConfig?.dot_background_color || '#5a67d8',
                user_message_color: openAIConfig?.user_message_color || '#5a67d8',
                user_message_background_color: openAIConfig?.user_message_background_color || '#ffffff',
                server_message_color: openAIConfig?.server_message_color || '#000000',
                server_message_background_color: openAIConfig?.server_message_background_color || '#f0f0f0'
            });
    
            // Set CSS variables for styling
            for (const [key, value] of Object.entries(openAIConfig)) {
                const cssVarName = `--${key.replace(/_/g, '-')}`;
                document.documentElement.style.setProperty(cssVarName, value || '');
            }
            
        } catch (error) {
            console.error('Failed to fetch welcome message:', error);
        }
    };
    
    useEffect(() => {
        fetchWelcomeMessage(localCompanyID);
    }, []); 

    return (
        <>
            <NavBar />
            <Container flex className="pt-5 pb-5">
                <Row>
                    <Col md={{ span: 4, offset: 1 }}>
                        <h2>Customize Chat Bot</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>Welcome Message <span onClick={() => setShowModal(true)}>?</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Welcome Message" 
                                    name="welcomeMessage"
                                    value={formData.welcomeMessage || ''} 
                                    onChange={handleChange}
                                    className={chatStyle.textareaInput} // Assuming you have defined this in your CSS
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Welcome Message Options</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Welcome Message Options" 
                                    name="welcomeMessageOptions"
                                    value={formData.welcomeMessageOptions || ''} 
                                    onChange={handleChange}
                                    className={chatStyle.textareaInput}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Chat Prompt</Form.Label>
                                <Form.Control 
                                    as="textarea"
                                    rows={3}
                                    placeholder="Chat Prompt" 
                                    name="contentPrompt"
                                    value={formData.contentPrompt || ''} 
                                    onChange={handleChange}
                                    className={chatStyle.textareaInput}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Container Width</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Container Width" 
                                    name="chat_container_width"
                                    value={openAIConfig?.chat_container_width || '400px'} 
                                    onChange={handleChangeConfig}
                                    className={chatStyle.textareaInput}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Container Height</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Container Height" 
                                    name="chat_container_height"
                                    value={openAIConfig?.chat_container_height || '400px'} 
                                    onChange={handleChangeConfig}
                                    className={chatStyle.textareaInput}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Toggle Button Width</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Toggle Button Width" 
                                    name="chat_toggle_button_width"
                                    value={openAIConfig?.chat_toggle_button_width || ''} 
                                    onChange={handleChangeConfig}
                                    className={chatStyle.textareaInput}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Toggle Button Height</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Toggle Button Height" 
                                    name="chat_toggle_button_height"
                                    value={openAIConfig?.chat_toggle_button_height || ''} 
                                    onChange={handleChangeConfig}
                                    className={chatStyle.textareaInput}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Toggle Button Color</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Control 
                                        type="color"
                                        id="chat_toggle_button_color"
                                        name="chat_toggle_button_color"
                                        value={openAIConfig?.chat_toggle_button_color || '#000000'} // Default color set to black
                                        onChange={handleChangeConfig}
                                        style={{ marginRight: '10px' }} // Add some space between the color picker and the text input
                                    />
                                    <Form.Control 
                                        type="text"
                                        id="chat_toggle_button_color_hex"
                                        name="chat_toggle_button_color_hex"
                                        value={openAIConfig?.chat_toggle_button_color || '#000000'} // Sync with color input
                                        onChange={handleChangeConfig} // Make sure this updates the main color state
                                        className={chatStyle.textareaInput}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Toggle Button Hover Color</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Control 
                                        type="color"
                                        id="chat_toggle_button_color_hover"
                                        name="chat_toggle_button_color_hover"
                                        value={openAIConfig?.chat_toggle_button_color_hover || '#000000'}
                                        onChange={handleChangeConfig}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <Form.Control 
                                        type="text"
                                        id="chat_toggle_button_color_hover_hex"
                                        name="chat_toggle_button_color_hover_hex"
                                        value={openAIConfig?.chat_toggle_button_color_hover || '#000000'}
                                        onChange={handleChangeConfig}
                                        className={chatStyle.textareaInput}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Header Background Color</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Control 
                                        type="color"
                                        id="chat_header_background_color"
                                        name="chat_header_background_color"
                                        value={openAIConfig?.chat_header_background_color || '#000000'}
                                        onChange={handleChangeConfig}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <Form.Control 
                                        type="text"
                                        id="chat_header_background_color_hex"
                                        name="chat_header_background_color_hex"
                                        value={openAIConfig?.chat_header_background_color || '#000000'}
                                        onChange={handleChangeConfig}
                                        className={chatStyle.textareaInput}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Input Button Color</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Control 
                                        type="color"
                                        id="chat_input_button_color"
                                        name="chat_input_button_color"
                                        value={openAIConfig?.chat_input_button_color || '#000000'}
                                        onChange={handleChangeConfig}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <Form.Control 
                                        type="text"
                                        id="chat_input_button_color_hex"
                                        name="chat_input_button_color_hex"
                                        value={openAIConfig?.chat_input_button_color || '#000000'}
                                        onChange={handleChangeConfig}
                                        className={chatStyle.textareaInput}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Input Button Background</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Control 
                                        type="color"
                                        id="chat_input_button_background"
                                        name="chat_input_button_background"
                                        value={openAIConfig?.chat_input_button_background || '#000000'}
                                        onChange={handleChangeConfig}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <Form.Control 
                                        type="text"
                                        id="chat_input_button_background_hex"
                                        name="chat_input_button_background_hex"
                                        value={openAIConfig?.chat_input_button_background || '#000000'}
                                        onChange={handleChangeConfig}
                                        className={chatStyle.textareaInput}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Dot Background Color</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Control 
                                        type="color"
                                        id="dot_background_color"
                                        name="dot_background_color"
                                        value={openAIConfig?.dot_background_color || '#000000'}
                                        onChange={handleChangeConfig}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <Form.Control 
                                        type="text"
                                        id="dot_background_color_hex"
                                        name="dot_background_color_hex"
                                        value={openAIConfig?.dot_background_color || '#000000'}
                                        onChange={handleChangeConfig}
                                        className={chatStyle.textareaInput}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>User Message Color</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Control 
                                        type="color"
                                        id="user_message_color"
                                        name="user_message_color"
                                        value={openAIConfig?.user_message_color || '#000000'}
                                        onChange={handleChangeConfig}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <Form.Control 
                                        type="text"
                                        id="user_message_color_hex"
                                        name="user_message_color_hex"
                                        value={openAIConfig?.user_message_color || '#000000'}
                                        onChange={handleChangeConfig}
                                        className={chatStyle.textareaInput}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>User Message Background Color</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Control 
                                        type="color"
                                        id="user_message_background_color"
                                        name="user_message_background_color"
                                        value={openAIConfig?.user_message_background_color || '#ffffff'} // Default white
                                        onChange={handleChangeConfig}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <Form.Control 
                                        type="text"
                                        id="user_message_background_color_hex"
                                        name="user_message_background_color_hex"
                                        value={openAIConfig?.user_message_background_color || '#ffffff'}
                                        onChange={handleChangeConfig}
                                        className={chatStyle.textareaInput}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="server_message_color">
                                <Form.Label>Server Message Color</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Control 
                                        type="color"
                                        id="server_message_color"
                                        name="server_message_color"
                                        value={openAIConfig?.server_message_color || '#ffffff'} // Default white
                                        onChange={handleChangeConfig}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <Form.Control 
                                        type="text"
                                        id="server_message_color_hex"
                                        name="server_message_color_hex"
                                        value={openAIConfig?.server_message_color || '#ffffff'}
                                        onChange={handleChangeConfig}
                                        className={chatStyle.textareaInput}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Server Message Background Color</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Control 
                                        type="color"
                                        id="server_message_background_color"
                                        name="server_message_background_color"
                                        value={openAIConfig?.server_message_background_color || '#ffffff'} // Default white
                                        onChange={handleChangeConfig}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <Form.Control 
                                        type="text"
                                        id="server_message_background_color_hex"
                                        name="server_message_background_color_hex"
                                        value={openAIConfig?.server_message_background_color || '#ffffff'}
                                        onChange={handleChangeConfig}
                                        className={chatStyle.textareaInput}
                                    />
                                </div>
                            </Form.Group>

                        <Button variant="primary" type="submit">
                                {isSaving ? 'Saving...' : 'Save'}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Welcome Message Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This is where you set the greeting message for your chat widget.</p>
                    <p>For example, "Welcome to our service! How can we assist you?"</p>
                    {/* If you want to show an image, include it here */}
                    <img src="/Welcome_Message.png" className="img-fluid" alt="Example chat window" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}