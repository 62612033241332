import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Features from './Features';
import Dashboard from './Dashboard';
import DataTraining from './DataTraining';
import Login from './Login';
import ChatBot from './ChatBot';
import Protection from './Protection';
import CreateAccount from './CreateAccount';
import ForgotPassword from './ForgotPassword';
import TermsService from './TermsService';
import PrivacyPolicy from './PrivacyPolicy';
import AdminDashboard from './AdminDashboard';
import PartnerDashboard from './PartnerDashboard';
import PartnerList from './PartnerList';
import PartnerSetup from './PartnerSetup';
import CreatePartner from './CreatePartner';
import ScheduleDemo from './ScheduleDemo';
import GetStarted from './GetStarted';
import FAQ from './FAQ';
import Logout from './Logout';
import CustomizeChatbot from './CustomizeChatbot';
import libsBundleStyles from "./assets/css/libs.bundle.css";
import themeBundleStyles from "./assets/css/theme.bundle.css";
import stylesUrl from "./assets/css/Layout.css";
import AuthRequireModal from './AuthRequireModal';

export const links = () => {
  return [
    { rel: "stylesheet", href: libsBundleStyles },
    { rel: "stylesheet", href: themeBundleStyles },
    { rel: "stylesheet", href: stylesUrl }
  ];
};

function App() {
  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleCloseAuthModal = () => setShowAuthModal(false);

  const handleShowAuthModal = () => setShowAuthModal(true);

  return (

    <div>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        {/* <Route path="/Features" element={<Features />} /> */}
        {/* <Route path="/DataTraining" element={<DataTraining />} /> */}
        <Route path="/CustomizeChatbot" element={<CustomizeChatbot />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/ChatBot" element={<ChatBot />} />
        <Route path="/GetStarted" element={<GetStarted />} />
        <Route path="/Protection" element={<Protection />} />
        <Route path="/CreateAccount" element={<CreateAccount />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/TermsService" element={<TermsService />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/AdminDashboard" element={<AdminDashboard setShowAuthModal={setShowAuthModal} />} />
        <Route path="/PartnerDashboard" element={<PartnerDashboard />} />
        <Route path="/PartnerList" element={<PartnerList />} />
        <Route path="/PartnerSetup" element={<PartnerSetup />} />
        <Route path="/CreatePartner" element={<CreatePartner />} />
        <Route path="/ScheduleDemo" element={<ScheduleDemo />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/Logout" element={<Logout />} />
      </Routes>
      
      <AuthRequireModal show={showAuthModal} handleClose={handleCloseAuthModal} />
    </div>
  );
}

export default App;
