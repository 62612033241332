import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './ChatHistoryStyle.css';

const LiveChatbot = ({ shop }) => {
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const chatWindowRef = useRef(null);

  const fetchChatSession = async (sessionId) => {
    try {
      const response = await axios.get(`https://brella-chatbot-64b531dcb02f.herokuapp.com/chatsessions/session/${sessionId}?companyId=${shop}`, {
        headers: {
          'Authorization': 'Bearer test123'
        }
      });
      setMessages(response.data.messages);
    } catch (error) {
      console.error('Error fetching chat session messages:', error);
    }
  };

  useEffect(() => {
    // Fetch all chat sessions by company ID
    axios.get(`https://brella-chatbot-64b531dcb02f.herokuapp.com/chatsessions/company/${shop}?liveChatRequested=true&companyId=${shop}`, {
        headers: {
          'Authorization': 'Bearer your_token_here'
        }
      })
      .then(response => {
        setSessions(response.data);
      })
      .catch(error => {
        console.error('Error fetching chat sessions:', error);
      });
  }, [shop]);

  useEffect(() => {
    let interval;
    if (selectedSession) {
      interval = setInterval(async () => {
        await fetchChatSession(selectedSession);
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [selectedSession]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSessionClick = (sessionId) => {
    setSelectedSession(sessionId);
    fetchChatSession(sessionId);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const message = {
        role: 'server',
        text: newMessage
      };

      axios.post(`https://brella-chatbot-64b531dcb02f.herokuapp.com/chatsessions/session/${selectedSession}/message?companyId=${shop}`, message, {
        headers: {
          'Authorization': 'Bearer your_token_here'
        }
      })
      .then(response => {
        setMessages(messages => [...messages, message]);
        setNewMessage('');
      })
      .catch(error => {
        console.error('Error sending message:', error);
      });
    }
  };

  return (
    <Container className="pt-5 pb-5">
      <Row>
        <Col xs={4} style={{ overflowY: 'scroll', maxHeight: '80vh' }}>
          <h3>Sessions</h3>
          <ListGroup>
            {sessions.map(session => (
              <ListGroupItem
                key={session._id}
                action
                onClick={() => handleSessionClick(session.sessionId)}
              >
                {session.sessionId}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
        <Col xs={8}>
          <h3>Chat Window</h3>
          <div className='chat-window' ref={chatWindowRef}>
            {selectedSession ? (
              messages.length > 0 ? (
                messages.map((message, index) => (
                  <div key={message._id || index} className={`message ${message.role}`}>
                    <div className="role">{message.role}</div>
                    <div
                      className="message-text"
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    ></div>
                  </div>
                ))
              ) : (
                <p>No messages found for this session.</p>
              )
            ) : (
              <p>Select a session to view messages.</p>
            )}
          </div>
          {selectedSession && (
            <Form className="mt-3" onSubmit={(e) => { e.preventDefault(); handleSendMessage(); }}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Type your message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
              </Form.Group>
              <Button type="submit" disabled={!newMessage.trim()}>Send</Button>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default LiveChatbot;
