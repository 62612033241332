import React, { useState } from 'react';
import NavBar from './NavbarMenu';
import { Container, Row, Image, Col, Alert, Form, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

import stylesUrl from "./assets/css/Layout.css";
import { fetchWithSessionToken } from './session_http_helper';

export const links = () => {
  return [
    { rel: "stylesheet", href: stylesUrl }
  ];
};

function LoginPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [shop, setShop] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    userId: '',
    password: '',
    agreedToTerms: true
  });

  const { userId, password, agreedToTerms } = formData;

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleInputChange = (e) => {
    setLoginError(false);
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    if (e.target.id === "newPassword") setNewPassword(e.target.value);
    if (e.target.id === "confirmPassword") setConfirmPassword(e.target.value);
  };

  const handlePasswordSubmit = async () => {
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      const shopWithDomain = `brella_web`;
      const usernameFromForm = formData.userId;

      const hashedPasswordNew = CryptoJS.SHA256(newPassword).toString();
      
      // Call the update password API using the hashed password
      const updateResponse = await fetchWithSessionToken(`https://brella-protect-514098c560ec.herokuapp.com/companies/${shopWithDomain}/users/${usernameFromForm}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ password: hashedPasswordNew })
      });

      if (!updateResponse.ok) {
        throw new Error(`Error updating password: ${updateResponse.status}`);
      }

      const loginInfo = await updateResponse.json();

      setShowPasswordModal(false);
    } catch (error) {
      console.error('Error updating password:', error);
      alert('Failed to update password. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLoginError(false);
  
    try {
      const payload = { ...formData, companyId: "brella_web" };

      if (formData.password && formData.password.trim() !== "") {
        payload.password = CryptoJS.SHA256(formData.password).toString();
      }
      
      const shop = 'brella_web';
      const checkResponse = await fetch(`https://brella-protect-514098c560ec.herokuapp.com/companies/${shop}/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      setIsLoading(false);
  
      if (checkResponse.status !== 200) {
        throw new Error(`Error: ${checkResponse.status}`);
      }
  
      const loginInfo = await checkResponse.json();
      localStorage.setItem('userId', formData.userId);
      localStorage.setItem('permissionLevel', loginInfo.permissionLevel);
      localStorage.setItem('sessionToken', loginInfo.token);

      if (loginInfo.needsPasswordUpdate) {
        setShowPasswordModal(true);
        return;
      }

      if (loginInfo.permissionLevel === "PARTNER") {
        // const partnerId = loginInfo.partnerId;
        const partnerId = 'd32b6bc4-3671-4095-9503-8c48430ec6b0';
        localStorage.setItem('partnerId', partnerId);
        navigate('/PartnerDashboard');
      } else {
        navigate('/AdminDashboard');
      }


      if (!loginInfo.verified) {
        localStorage.setItem('loggedIn', 'true');
        localStorage.setItem('userVerification', false);
      } else {
        // If the user is verified, navigate to the dashboard
        localStorage.setItem('loggedIn', 'true');
        localStorage.setItem('permissionLevel', loginInfo.permissionLevel);
        localStorage.setItem('userVerification', true);
      }

    } catch (error) {
      console.error('Login failed:', error);
      setIsLoading(false);
      setLoginError(true);
      setFormData({ userId: '', password: '', agreedToTerms: true }); // Clear input fields on error
    }
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setFormData({
      ...formData,
      [id]: checked
    });
  };
  
  return (
    <>
      <NavBar shouldLoadChatWidget={false} />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Container>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Image src="brella_logo.png" className="mb-4" fluid style={{ width: '200px', height: 'auto' }} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={5} xl={4} className="my-5 border rounded shadow p-3">
              <h1 className="display-4 text-center mb-3">Sign in</h1>
              {loginError && (
                <Alert variant="danger" className="error-message">
                  Login failed
                </Alert>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="text"
                    id="userId"
                    value={formData.userId}
                    onChange={handleInputChange}
                    placeholder="name@address.com"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <div className="d-flex justify-content-between">
                    <Form.Label>Password</Form.Label>
                    <Link to={`/ForgotPassword`} className="form-text small text-body-secondary">
                      Forgot password?
                    </Link>
                  </div>
                  <div className="input-group">
                    <Form.Control
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      placeholder="Enter your password"
                    />
                    <Button variant="outline-secondary" onClick={togglePasswordVisibility}>
                      <i className={passwordVisible ? "fe fe-eye-off" : "fe fe-eye"}></i>
                    </Button>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id="agreedToTerms"
                    checked={agreedToTerms}
                    onChange={handleCheckboxChange}
                    label={<span>Agree to <Link to="/TermsService">terms and conditions</Link></span>}
                  />
                </Form.Group>
                <Button type="submit" className="custom-btn-primary mb-3" size="lg" disabled={isLoading || !agreedToTerms}>
                  {isLoading ? 'Logging in...' : 'Sign in'}
                </Button>
                <div className="text-center">
                  <small className="text-body-secondary text-center">
                    Don't have an account yet? <Link className="link" to={`/CreateAccount`}>Sign up</Link>.
                  </small>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Set a New Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={handlePasswordChange}
                  placeholder="Enter new password"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={handlePasswordChange}
                  placeholder="Confirm new password"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowPasswordModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handlePasswordSubmit}>
              Save Password
            </Button>
          </Modal.Footer>
        </Modal>

    </>
  );
}

export default LoginPage;
