import React, { useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, Container, NavDropdown, NavLink } from 'react-bootstrap';
import './assets/css/CustomNavbar.css';

function NavBarMenu({ shouldLoadChatWidget }) {
  const [showDropdownGetStarted, setShowDropdownGetStarted] = useState(false);
  const [showDropdownCurrentOffers, setShowDropdownCurrentOffers] = useState(false);
  const [userLogin, setUserLogin] = useState(false);
  const [permissionLevel, setPermissionLevel] = useState('BASIC');

  const dropdownRefGetStarted = useRef(null);
  const dropdownRefCurrentOffers = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefGetStarted.current && 
        !dropdownRefGetStarted.current.contains(event.target) &&
        dropdownRefCurrentOffers.current && 
        !dropdownRefCurrentOffers.current.contains(event.target)
      ) {
        setShowDropdownGetStarted(false);
        setShowDropdownCurrentOffers(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const userLoggedIn = localStorage.getItem('loggedIn') === 'true';
    const userPermission = localStorage.getItem('permissionLevel'); // e.g., "ADMIN", "PARTNER", etc.
    setUserLogin(userLoggedIn);
    setPermissionLevel(userPermission || 'BASIC');
  }, []);

  const handleDropdownClick = (dropdown) => {
    if (dropdown === 'currentOffers') {
      setShowDropdownCurrentOffers((prevState) => !prevState);
      setShowDropdownGetStarted(false);
    } else if (dropdown === 'getStarted') {
      setShowDropdownGetStarted((prevState) => !prevState);
      setShowDropdownCurrentOffers(false);
    }
  };

  const handleScheduleDemoClick = () => {
    console.log('Schedule Demo Clicked');
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: 'https://calendly.com/brent-brella/test'
      });
    } else {
      console.error('Calendly is not loaded');
    }
  };

  useEffect(() => {
    const shopId = 'brella-ai';

    // const loadChatWidget = async (shopId) => {
    //   const script = document.createElement('script');
    //   script.src = "https://brella-ai-chatwidget-01f675efcccd.herokuapp.com/ChatWidget.js";
    //   script.onload = () => {
    //     console.log("ChatWidget.js loaded successfully");
    //     window.initChatWidget('#chat-widget-container');
    //   };
    //   script.onerror = () => {
    //     console.error("Error loading ChatWidget.js");
    //   };
    //   document.body.appendChild(script);

    //   const companyIdScript = document.createElement('script');
    //   companyIdScript.type = "text/javascript";
    //   companyIdScript.text = `var companyId = '${shopId}';`;
    //   document.body.appendChild(companyIdScript);

    //   console.log("companyIdScript added to body");

    //   const chatbotStylesheet = document.createElement('link');
    //   chatbotStylesheet.rel = 'stylesheet';
    //   chatbotStylesheet.href = 'https://brella-ai-chatwidget-01f675efcccd.herokuapp.com/ChatWidget.css';
    //   chatbotStylesheet.onload = () => {
    //     console.log("ChatWidget.css loaded successfully");
    //   };
    //   chatbotStylesheet.onerror = () => {
    //     console.error("Error loading ChatWidget.css");
    //   };
    //   document.head.appendChild(chatbotStylesheet);
    // };

    // if (shouldLoadChatWidget) {
    //   loadChatWidget(shopId);
    // }
  }, [shouldLoadChatWidget]);

  return (
      <Navbar bg="white" expand="lg" fixed="top">
        <Container className="px-5">
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                src="https://d1f6rj4sne1926.cloudfront.net/brella_logo.png"
                alt="Logo"
                className="navbar-brand-img"
              />
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link>About</Nav.Link>
              </LinkContainer>
              {/* <NavDropdown title="Products" id="admin-dropdown">
                <LinkContainer to="/ChatBot">
                  <NavDropdown.Item>Chat Bot</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown> */}
            </Nav>

            {/* Dropdown for Admin and Partner Pages */}
            {userLogin && (
              <Nav>
                <NavDropdown title="Admin Tools" id="admin-dropdown">
                  {permissionLevel === 'SUPER_ADMIN' && (
                    <>
                      <LinkContainer to="/AdminDashboard">
                        <NavDropdown.Item>Admin Dashboard</NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Divider /> {/* Divider */}
                      <LinkContainer to="/PartnerDashboard">
                        <NavDropdown.Item>Partner Dashboard</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/PartnerSetup">
                        <NavDropdown.Item>Partner Setup</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/CustomizeChatbot">
                        <NavDropdown.Item>Customize Chatbot</NavDropdown.Item>
                      </LinkContainer>
                    </>
                  )}
                  {permissionLevel === 'ADMIN' && (
                    <>
                      <LinkContainer to="/AdminDashboard">
                        <NavDropdown.Item>Admin Dashboard</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/CustomizeChatbot">
                        <NavDropdown.Item>Customize Chatbot</NavDropdown.Item>
                      </LinkContainer>
                    </>
                  )}
                  {permissionLevel === 'PARTNER' && (
                    <LinkContainer to="/PartnerDashboard">
                      <NavDropdown.Item>Partner Dashboard</NavDropdown.Item>
                    </LinkContainer>
                  )}
                </NavDropdown>
              </Nav>
            )}

            <Nav>
              <LinkContainer to={userLogin ? "/Logout" : "/Login"}>
                <Nav.Link className="button">
                  {userLogin ? "Logout" : "Login"}
                </Nav.Link>
              </LinkContainer>

              {!userLogin && (
                <LinkContainer to="/ScheduleDemo">
                  <Nav.Link className="button">
                    Schedule Demo
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}

export default NavBarMenu;
