import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useRef } from 'react';
import Slider from "react-slick";
import NavBarMenu from './NavbarMenu';
import { Container, Image, Row, Col, Button } from 'react-bootstrap';
import Footer from './Footer';

const partnerLogos = [
  { src: 'biolight.png', alt: 'Partner 1' },
  { src: 'jovi.png', alt: 'Partner 2' },
  { src: 'signal.png', alt: 'Partner 3' }
];

const Dashboard = () => {
  const topContainerRef = useRef(null);

  const handleViewProductsClick = () => {
    if (topContainerRef.current) {
      topContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Slick Slider settings for slow, continuous movement
  const settings = {
    dots: false,               // No dots for smooth continuous movement
    infinite: true,            // Infinite loop for the carousel
    speed: 9000,               // Transition speed (9 seconds per slide)
    slidesToShow: 3,           // Number of slides to show at once
    slidesToScroll: 1,         // Scroll 1 slide at a time
    autoplay: true,            // Automatically scroll the slides
    autoplaySpeed: 0,          // With `cssEase: 'linear'`, this creates continuous movement
    cssEase: 'linear',         // Linear easing for smooth scrolling
    pauseOnHover: false,       // Do not pause the carousel on hover
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <NavBarMenu shouldLoadChatWidget={true} />
      <Container fluid style={{
        background: "linear-gradient(to right, #b0a6ff, #dff7ff)",
        paddingTop: '200px',
        paddingBottom: '200px',
        textAlign: 'center'
      }}>
        <Row className="justify-content-center align-items-center">
          <Col lg={6} md={8}>
            <h1>Brella.ai: Elevate Your E-Commerce Experience with Effortless Protection</h1>
            <p>Unlock the future of online retail with Brella.ai's AI-powered tools...</p>
            <Container>
              <Row className="justify-content-center pt-4">
                <Col xs={12} md={6} lg={4} className="d-grid gap-2">
                  <Button variant="outline-primary" size="lg" className="fw-bold" onClick={handleViewProductsClick}>
                    View Products
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <Container
        ref={topContainerRef}
        fluid
        style={{
          paddingTop: '100px',
          paddingBottom: '100px',
          textAlign: 'center'
        }}
      >
        <Row className="justify-content-center align-items-center">
          <Col lg={6} md={8} className="order-lg-2 order-md-1">
            <Row className="justify-content-center align-items-center">
              <Col xs={12} md={6} lg={8}>
                <h2>Happy Shipments Await!</h2>
                <p>
                  Our Shipping Protection Widget ensures deliveries arrive safely and on time, 
                  boosting customer satisfaction and reducing cart abandonment. Say goodbye to 
                  chargebacks and hello to increased revenue. Supercharge your store today!
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={8} className="order-lg-1 order-md-2">
            <Row className="justify-content-center align-items-center">
              <Col xs={12} md={6} lg={8} style={{ padding: '20px', maxWidth: '1300px' }}>
                <Image src="happy_customer.webp" alt="Image" fluid rounded />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid style={{
        paddingTop: '100px',
        paddingBottom: '100px',
        textAlign: 'center'
      }}>
        <Row className="justify-content-center align-items-center">
          <Col lg={6} md={8} sm={12} xs={12} className="order-lg-1 order-md-2">
            <Row className="justify-content-center align-items-center">
              <Col xs={12} md={6} lg={8}>
                <h2>Engage With Customers</h2>
                <p>
                  Our Personalized Chatbot service provides 24/7 tailored support to your customers,
                  helping them find answers, resolve issues, and get personalized recommendations.
                  Boost customer loyalty, reduce support queries, and increase sales with a
                  chatbot that understands your customers' unique needs. Elevate your customer
                  experience today!
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={8} sm={12} xs={12} className="order-lg-2 order-md-1">
            <Row className="justify-content-center align-items-center">
              <Col xs={12} md={6} lg={8} style={{ padding: '20px', maxWidth: '1300px' }}>
                <Image src="chatbot_resolver.webp" alt="Image" fluid rounded />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* Carousel for partner logos */}
      <Container fluid style={{ paddingTop: '100px', paddingBottom: '100px', textAlign: 'center' }}>
        <h2>Our Trusted Partners</h2>
        <Slider {...settings}>
          {partnerLogos.map((logo, index) => (
            <div key={index} style={{ padding: '10px' }}>
              <Image
                src={logo.src}
                alt={logo.alt}
                fluid
                style={{ maxWidth: '150px', margin: '0 auto', height: '100px', objectFit: 'contain' }}  // Adjusted width and height
              />
            </div>
          ))}
        </Slider>
      </Container>

      <Footer />
    </>
  );
};

export default Dashboard;
