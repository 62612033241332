import { startOfWeek, endOfWeek, addDays, subDays } from 'date-fns';

// Helper function to get the start and end of today
export function getToday() {
  const today = new Date();
  const start = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const end = new Date(today.getFullYear(), today.getMonth(), today.getDate()); // Set end of day
  return [start, end];
}

// Helper function to get the start and end of this week
export function getThisWeek() {
  const today = new Date();
  const start = startOfWeek(today, { weekStartsOn: 1 }); // Set week start to Monday
  const end = endOfWeek(today, { weekStartsOn: 1 });
  return [start, end];
}

// Helper function to get the start and end of last week
export function getLastWeek() {
  const today = new Date();
  const start = startOfWeek(subDays(today, 7), { weekStartsOn: 1 });
  const end = endOfWeek(subDays(today, 7), { weekStartsOn: 1 });
  return [start, end];
}

// Helper function to get the start and end of this month
export function getThisMonth() {
  const today = new Date();
  const start = new Date(today.getFullYear(), today.getMonth(), 1);
  const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  return [start, end];
}

export function getThisYear() {
  const today = new Date();
  const start = new Date(today.getFullYear(), 0, 1); // January 1st of this year
  const end = new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999); // December 31st of this year, end of day
  return [start, end];
}

// Helper function to get the start and end of last month
export function getLastMonth() {
  const today = new Date();
  const start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const end = new Date(today.getFullYear(), today.getMonth(), 0);
  return [start, end];
}
