import React from 'react';
import { Container, Row, Col, Form, FormControl, Button } from 'react-bootstrap';

const StyleChatbot = ({ openAIConfig, handleChangeConfig, handleSubmit, isSaving }) => (
    <Container className="pt-5 pb-5">
        <Row>
            <Col md={{ span: 8, offset: 1 }}>
                <Form onSubmit={handleSubmit}>
                    {[
                        { id: 'chat_container_width', label: 'Container Width', placeholder: '400px' },
                        { id: 'chat_container_height', label: 'Container Height', placeholder: '400px' },
                        { id: 'chat_toggle_button_width', label: 'Toggle Button Width', placeholder: '50px' },
                        { id: 'chat_toggle_button_height', label: 'Toggle Button Height', placeholder: '50px' }
                    ].map(({ id, label, placeholder }) => (
                        <Form.Group className="mb-3" key={id}>
                            <Form.Label htmlFor={id}>{label}</Form.Label>
                            <FormControl
                                id={id}
                                type="text"
                                placeholder={placeholder}
                                name={id}
                                value={openAIConfig[id] || ''}
                                onChange={handleChangeConfig}
                            />
                        </Form.Group>
                    ))}

                    {[
                        { id: 'chat_input_background', label: 'Chat Input Background', defaultValue: '#f7f7f7' },
                        { id: 'chat_input_input_background', label: 'Chat Input Input Background', defaultValue: '#f7f7f7' },
                        { id: 'chat_toggle_button_color', label: 'Toggle Button Color', defaultValue: '#5a67d8' },
                        { id: 'chat_toggle_button_color_hover', label: 'Toggle Button Hover Color', defaultValue: '#4c51bf' },
                        { id: 'chat_header_background_color', label: 'Header Background Color', defaultValue: '#ffffff' },
                        { id: 'chat_input_button_color', label: 'Input Button Color', defaultValue: '#5a67d8' },
                        { id: 'chat_input_button_color_hover', label: 'Input Button Color Hover', defaultValue: '#0056b3' },
                        { id: 'chat_input_button_background', label: 'Input Button Background', defaultValue: '#f7f7f7' },
                        { id: 'dot_background_color', label: 'Dot Background Color', defaultValue: '#5a67d8' },
                        { id: 'user_message_color', label: 'User Message Color', defaultValue: '#5a67d8' },
                        { id: 'user_message_background_color', label: 'User Message Background Color', defaultValue: '#ffffff' },
                        { id: 'server_button_background_color', label: 'Server Button Background Color', defaultValue: '#4CAF50' },
                        { id: 'server_button_background_color_hover', label: 'Server Button Background Hover Color', defaultValue: '#4CAF50' },
                        { id: 'server_message_color', label: 'Server Message Color', defaultValue: '#000000' },
                        { id: 'server_message_background_color', label: 'Server Message Background Color', defaultValue: '#f0f0f0' },
                        { id: 'welcome_message_options', label: 'Welcome Message Options Background', defaultValue: '#fff' }
                    ].map(({ id, label, defaultValue }) => (
                        <Form.Group className="mb-3" key={id}>
                            <Form.Label htmlFor={id}>{label}</Form.Label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FormControl
                                    type="color"
                                    id={id}
                                    name={id}
                                    value={openAIConfig[id] || defaultValue}
                                    onChange={handleChangeConfig}
                                    style={{ marginRight: '10px' }}
                                />
                                <FormControl
                                    type="text"
                                    id={`${id}_hex`}
                                    name={`${id}_hex`}
                                    value={openAIConfig[id] || defaultValue}
                                    onChange={handleChangeConfig}
                                    className="textareaInput"
                                />
                            </div>
                        </Form.Group>
                    ))}

                    <Button type="submit">
                        {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                </Form>
            </Col>
        </Row>
    </Container>
);

export default StyleChatbot;
