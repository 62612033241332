import React from 'react';
import { Container, Image, Card, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavBarMenu from './NavbarMenu';

function TermsService() {
  return (
    <>
      <NavBarMenu shouldLoadChatWidget={false} />
      <div style={{paddingTop: '100px'}}>
        <Container className="py-5" >
          <Row className="justify-content-center">
            <Image src="brella_logo.png" className="mb-4" fluid style={{ width: '200px', height: 'auto' }}/>
          </Row>
          <Card className="shadow">
            <Card.Body>
              <h1 className="text-center mb-4">Terms of Service</h1>

            <h2>Introduction</h2>
          <p>Welcome to Brella.ai LLC. By accessing our Enhanced AI E-commerce Suite of Services, you agree to be bound by these Terms of Service. These terms govern your use of Brella.ai LLC and provide information about our service, outlined below.</p>

          <h2>Acceptance of Terms</h2>
          <p>By accessing or using our services, you confirm your agreement to be bound by these terms. If you do not agree to these terms, please do not use our services.</p>

          <h2>Modification of Terms</h2>
          <p>Brella.ai LLC reserves the right, at our discretion, to change, modify, add, or remove portions of these Terms at any time. Your continued use of the service following the posting of changes will mean that you accept and agree to the changes.</p>

          <h2>Account Registration and Use</h2>
          <p>Registration consists of the following:</p>
          <ul>
            <li>Input User information</li>
            <li>Customer Name</li>
            <li>Email</li>
            <li>Phone</li>
          </ul>
          <p>Brella.ai LLC is not responsible for lost or stolen passwords or login information and urges all users to take precautionary measures to keep their login information safe.</p>

          <h2>User Responsibilities and Conduct</h2>
          <p>Users are responsible for their conduct and must comply with our <a href="#">policies</a>. Prohibited activities include:</p>
          <ul>
            <li>Illegal Activities</li>
            <li>Infringement of Intellectual Property</li>
            <li>Hacking and Unauthorized Access</li>
            <li>Distributing Harmful Software</li>
            <li>Abusive or Offensive Conduct</li>
            <li>Fraud and Misrepresentation</li>
            <li>Spamming</li>
            <li>Data Scraping</li>
            <li>Interference with Operations</li>
            <li>Violation of Privacy</li>
          </ul>

          <h2>Intellectual Property Rights</h2>
          <p>All content on Brella.ai LLC, including TradeMark Information, Copyrighted Material, Patents, Trade Secrets, User Content, etc. is the exclusive property of Brella.ai LLC and its licensors.</p>

          <h2>Disclaimer of Warranties</h2>
          <p>The service is provided "as is" and "as available" without any warranties, express or implied.</p>

          <h2>Limitation of Liability</h2>
          <p>Brella.ai LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>

          <h2>Indemnification</h2>
          <p>You agree to indemnify and hold harmless Brella.ai LLC from any claim or demand, including reasonable attorney’s fees.</p>

          <h2>Dispute Resolution and Governing Law</h2>
          <p>These Terms will be governed by Utah State Law. Any disputes will be resolved through any of the following:</p>
          <ul>
            <li>Jurisdiction</li>
            <li>Arbitration</li>
            <li>Mediation</li>
          </ul>

          <h2>Termination and Suspension</h2>
          <p>We may terminate or suspend access to our service immediately, without prior notice or liability.</p>

          <h2>General Provisions</h2>
          <p>General legal provisions including entire agreement, waiver, severability, and assignment clauses.</p>

          <h2>Contact Information</h2>
          <p>For any questions about these Terms, please contact us at: brent@brella.ai</p>

          <h2>Effective Date</h2>
          <p>These Terms are effective the day your services are activated and will remain in effect except with respect to any changes in its provisions in the future.</p>

            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default TermsService;
