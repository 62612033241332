import React from 'react';
import { Container, Row, Col, Form, FormControl, Button, ProgressBar, ListGroup } from 'react-bootstrap';
import DiscoveredUrls from './DiscoveredUrls';
import TrainedUrls from './TrainedUrls';

const TrainChatBot = ({
    handleSearch, isTrainDataDisabled, updateVectorDB, handleCancel, isSearching, progress, searchResults,
    handleCheckboxChange, selectedWebsites, selectAll, handleSelectAllChange, baseUrl, query, results,
    setQuery, handleQuery, deleteVectors, companyId, discoveredUrls, trainedUrls, handleTrainUrls, saveDiscoveredUrls, deleteDiscoveredUrls, deleteTrainedUrls
}) => {
    return (
        <Container className="pt-5 pb-5">
            <Row className="mb-4">
                <Col md={4}>
                    <Form inline onSubmit={handleSearch}>
                        <Form.Label htmlFor="inlineFormInput" className="mr-2">Enter Website</Form.Label>
                        <FormControl
                            type="text"
                            placeholder="Base URL"
                            className="mr-2"
                            defaultValue={baseUrl}
                            readOnly={isSearching}
                        />
                        <Button className="train-bot-button" type="submit" disabled={isSearching}>Search</Button>
                        {isSearching && (
                            <Button variant="danger" className="train-bot-button" onClick={handleCancel}>Cancel</Button>
                        )}
                        {searchResults.length > 0 && (
                            <ListGroup>
                                <ListGroup.Item >
                                    <Form.Check
                                        type="checkbox"
                                        label="Select All"
                                        checked={selectAll}
                                        onChange={handleSelectAllChange}
                                    />
                                </ListGroup.Item>
                                {searchResults.map((site) => (
                                    <ListGroup.Item className="list-group-item-custom" key={site.id}>
                                        <Form.Check
                                            type="checkbox"
                                            label={site.url}
                                            checked={!!selectedWebsites[site.id]}
                                            onChange={() => handleCheckboxChange(site.id)}
                                        />
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                        <Button variant="secondary" onClick={saveDiscoveredUrls} className="mt-2">
                            Save Discovered URLs
                        </Button>
                    </Form>
                    {isSearching && (
                        <ProgressBar now={progress} label={`${Math.round(progress)}%`} className="mt-3" />
                    )}
                </Col>
                <Col md={4}>
                    <DiscoveredUrls
                        discoveredUrls={discoveredUrls}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedUrls={selectedWebsites}
                        handleTrainUrls={handleTrainUrls}
                        saveDiscoveredUrls={saveDiscoveredUrls}
                        deleteDiscoveredUrls={deleteDiscoveredUrls}
                    />
                </Col>
                <Col md={4}>
                    <TrainedUrls 
                        trainedUrls={trainedUrls}
                        deleteTrainedUrls={deleteTrainedUrls}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default TrainChatBot;
