import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card, FormControl } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

const ChatbotMessage = ({ formData, welcomeMessages, handleChange, handleChangeConfigMessages, handleSubmit, handleAddRow, handleDeleteRow, isSaving, data }) => {
    const [iconImage, setIconImage] = useState(null);
    const [headerImage, setHeaderImage] = useState(null);

    useEffect(() => {
        console.log("Data prop:", data);  // Log to confirm data prop is being passed
        if (data) {
            const { chatbotIconImage, chatbotHeaderImage } = data;
            console.log("chatbotIconImage:", chatbotIconImage);  // Log to confirm individual data fields
            console.log("chatbotHeaderImage:", chatbotHeaderImage);
            if (chatbotIconImage) {
                setIconImage(chatbotIconImage);
            }
            if (chatbotHeaderImage) {
                setHeaderImage(chatbotHeaderImage);
            }
        }
    }, [data]);
    const onDropIcon = (acceptedFiles) => {
        setIconImage(acceptedFiles[0]);
    };

    const onDropHeader = (acceptedFiles) => {
        setHeaderImage(acceptedFiles[0]);
    };

    const handleRemoveIcon = () => {
        setIconImage(null);
    };

    const handleRemoveHeader = () => {
        setHeaderImage(null);
    };

    const StatesEnum = {
        FILE_CLAIM: "File Claim",
        INFO: "Store Info",
        CUSTOMER_SUPPORT: "Customer Support",
        SHIPPING_STATUS: "Shipping Status",
        LIVE_CHAT: "Connect to live chat",
    };

    const { getRootProps: getIconRootProps, getInputProps: getIconInputProps } = useDropzone({ onDrop: onDropIcon, multiple: false });
    const { getRootProps: getHeaderRootProps, getInputProps: getHeaderInputProps } = useDropzone({ onDrop: onDropHeader, multiple: false });

    return (
        <Container className="pt-5 pb-5">
            <Row>
                <Col md={{ span: 8, offset: 1 }}>
                    <Form onSubmit={(e) => handleSubmit(e, iconImage, headerImage)}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="chatbotHeaderTitle">
                                Chatbot Header Title
                            </Form.Label>
                            <FormControl
                                id="chatbotHeaderTitle"
                                type="text"
                                placeholder="Enter Chatbot Header Title"
                                name="chatbotHeaderTitle"
                                value={formData.chatbotHeaderTitle || ''}
                                onChange={handleChange}
                                className="textareaInput"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="chatbotHeaderDescription">
                                Chatbot Header Description
                            </Form.Label>
                            <FormControl
                                id="chatbotHeaderDescription"
                                type="text"
                                placeholder="Enter Chatbot Header Description"
                                name="chatbotHeaderDescription"
                                value={formData.chatbotHeaderDescription || ''}
                                onChange={handleChange}
                                className="textareaInput"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="chatbotName">
                                Chatbot Server Name
                            </Form.Label>
                            <FormControl
                                id="chatbotName"
                                type="text"
                                placeholder="Enter Chatbot Server Name"
                                name="chatbotName"
                                value={formData.chatbotName || ''}
                                onChange={handleChange}
                                className="textareaInput"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="userName">
                                User Name
                            </Form.Label>
                            <FormControl
                                id="userName"
                                type="text"
                                placeholder="Enter User Name Title"
                                name="userName"
                                value={formData.userName || ''}
                                onChange={handleChange}
                                className="textareaInput"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="welcomeMessage">
                                Welcome Message
                            </Form.Label>
                            <FormControl
                                id="welcomeMessage"
                                type="text"
                                placeholder="Welcome Message"
                                name="welcomeMessage"
                                value={formData.welcomeMessage || ''}
                                onChange={handleChange}
                                className="textareaInput"
                            />
                        </Form.Group>

                        <Card body>
                            <Card.Title>Map button text to actions</Card.Title>
                            {welcomeMessages.map((message, index) => (
                                <Form.Group as={Row} className="mb-3" key={index}>
                                    <Col sm="6">
                                        <FormControl
                                            type="text"
                                            placeholder="Welcome Message"
                                            name={`welcome_message_${index}`}
                                            value={message.button_message || ''}
                                            onChange={(e) => handleChangeConfigMessages(e, index, 'button_message')}
                                            className="textareaInput"
                                        />
                                    </Col>
                                    <Col sm="4">
                                        <Form.Control
                                            as="select"
                                            name={`welcome_state_${index}`}
                                            value={message.chat_state || 'ORDER'}
                                            onChange={(e) => handleChangeConfigMessages(e, index, 'chat_state')}
                                        >
                                            {Object.entries(StatesEnum).map(([key, value]) => (
                                                <option key={key} value={key}>{value}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="danger" onClick={() => handleDeleteRow(index)}>
                                            Delete Action
                                        </Button>
                                    </Col>
                                </Form.Group>
                            ))}
                            <Button onClick={handleAddRow}>
                                Add Row
                            </Button>
                        </Card>

                        <Card body className="mb-3">
                            <Card.Title>Upload Icon Image</Card.Title>
                            <div {...getIconRootProps({ className: 'dropzone' })} style={dropzoneStyle}>
                                <input {...getIconInputProps()} />
                                {iconImage ? (
                                    typeof iconImage === 'string' ? (
                                        <div>
                                            <img src={iconImage} alt="Icon" style={{ maxWidth: '40%' }} />
                                            <Button 
                                                variant="danger" 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveIcon();
                                                }} 
                                                className="mt-2"
                                            >
                                                Remove Image
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <img src={URL.createObjectURL(iconImage)} alt="Icon" style={{ maxWidth: '40%' }} />
                                            <Button 
                                                variant="danger" 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveIcon();
                                                }} 
                                                className="mt-2"
                                            >
                                                Remove Image
                                            </Button>
                                        </div>
                                    )
                                ) : (
                                    <p>Drag & drop an icon image here, or click to select one</p>
                                )}
                            </div>
                        </Card>

                        <Card body className="mb-3">
                            <Card.Title>Upload Header Image</Card.Title>
                            <div {...getHeaderRootProps({ className: 'dropzone' })} style={dropzoneStyle}>
                                <input {...getHeaderInputProps()} />
                                {headerImage ? (
                                    typeof headerImage === 'string' ? (
                                        <div>
                                            <img src={headerImage} alt="Header" style={{ maxWidth: '40%' }} />
                                            <Button 
                                                variant="danger" 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveHeader();
                                                }} 
                                                className="mt-2"
                                            >
                                                Remove Image
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <img src={URL.createObjectURL(headerImage)} alt="Header" style={{ maxWidth: '40%' }} />
                                            <Button 
                                                variant="danger" 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveHeader();
                                                }} 
                                                className="mt-2"
                                            >
                                                Remove Image
                                            </Button>
                                        </div>
                                    )
                                ) : (
                                    <p>Drag & drop a header image here, or click to select one</p>
                                )}
                            </div>
                        </Card>

                        <Button type="submit">
                            {isSaving ? 'Saving...' : 'Save'}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

const dropzoneStyle = {
    border: '2px dashed #cccccc',
    borderRadius: '5px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer'
};

export default ChatbotMessage;
