import React, { useState } from 'react';
import { Form, Button, Card, Row, Container, Col, FormControl } from 'react-bootstrap';

const CreatePartner = ({ newPartner, handleSubmit, handleInputChange, handleCompanyNameChange, 
  handleCompanyIdChange, handleCompanyCommissionChange, handleAddCompanyRow, handleDeleteCompanyRow }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await handleSubmit(e);
    setIsLoading(false);
  };

  return (
    <Container className="pt-5 pb-5">
      <Row>
        <Col md={{ span: 8, offset: 1 }}>
          <Form onSubmit={onSubmit} className="partner-form">
            <Card body>
              <Card.Title>Companies</Card.Title>
              {newPartner.companyIds.map((company, index) => (
                <Form.Group as={Row} className="mb-3" key={index}>
                  <Col sm="10">
                    <FormControl
                      type="text"
                      placeholder="Company Name"
                      name={`company_name_${index}`}
                      value={company.companyName}
                      onChange={(e) => handleCompanyNameChange(e, index)}
                      className="textareaInput"
                    />
                    <FormControl
                      type="text"
                      placeholder="Company Id"
                      name={`company_id_${index}`}
                      value={company.companyId}
                      onChange={(e) => handleCompanyIdChange(e, index)}
                      className="textareaInput"
                    />
                    <FormControl
                      type="text"
                      placeholder="Commission"
                      name={`commission_${index}`}
                      value={company.commssion}
                      onChange={(e) => handleCompanyCommissionChange(e, index)}
                      className="textareaInput"
                    />
                  </Col>
                  <Col sm="2">
                    <Button variant="danger" onClick={() => handleDeleteCompanyRow(index)}>
                      Delete
                    </Button>
                  </Col>
                </Form.Group>
              ))}
              <Button onClick={handleAddCompanyRow}>
                Add Company
              </Button>
            </Card>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={newPartner.email}
                onChange={handleInputChange}
                placeholder="Enter email"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="create-partner-button" disabled={isLoading}>
              {isLoading ? 'Creating...' : 'Create Partner'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreatePartner;
