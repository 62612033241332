import React, { useEffect, useState } from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import axios from 'axios';
import './ChatHistoryStyle.css';

const HistoryChatbot = ({ shop }) => {
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Fetch all chat sessions by company ID (assuming 'server' as the company ID)
    axios.get(`https://brella-chatbot-64b531dcb02f.herokuapp.com/chatsessions/company/${shop}`)
      .then(response => {
        setSessions(response.data);
      })
      .catch(error => {
        console.error('Error fetching chat sessions:', error);
      });
  }, []);

  const handleSessionClick = (sessionId) => {
    // Fetch messages for the selected session
    axios.get(`https://brella-chatbot-64b531dcb02f.herokuapp.com/chatsessions/session/${sessionId}`)
      .then(response => {
        setSelectedSession(sessionId);
        setMessages(response.data.messages);
      })
      .catch(error => {
        console.error('Error fetching chat session messages:', error);
      });
  };

  return (
    <Container className="pt-5 pb-5">
      <Row>
        <Col xs={4} style={{ overflowY: 'scroll', maxHeight: '80vh' }}>
          <h3>Messages</h3>
          <ListGroup>
            {sessions.map(session => (
              <ListGroupItem
                key={session._id}
                action
                onClick={() => handleSessionClick(session.sessionId)}
              >
                {session.sessionId}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
        <Col xs={8}>
          <h3>Chat Window</h3>
          <div className='chat-window'>
            {selectedSession ? (
              messages.length > 0 ? (
                messages.map((message, index) => (
                  <div key={index} className={`message ${message.role}`}>
                    <div className="role">{message.role}</div>
                    <div
                      className="message-text"
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    ></div>
                  </div>
                ))
              ) : (
                <p>No messages found for this session.</p>
              )
            ) : (
              <p>Select a session to view messages.</p>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HistoryChatbot;
