import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useRef } from 'react';
import Slider from "react-slick";
import NavBarMenu from './NavbarMenu';
import { Container, Image, Row, Col, Button } from 'react-bootstrap';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const partnerLogos = [
  { src: 'Signal_Relief_logo.png', alt: 'Partner 1' },
  { src: 'skull_export.png', alt: 'Partner 2' },
  { src: 'Biolight.png', alt: 'Partner 3' },
  { src: 'Robby-layton.png', alt: 'Partner 4' },
  { src: 'jovi_logo.png', alt: 'Partner 5' }
];

const Dashboard = () => {
  const topContainerRef = useRef(null);

  const handleViewProductsClick = () => {
    if (topContainerRef.current) {
      topContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Slick Slider settings for slow, continuous movement
  const settings = {
    dots: true,               // No dots for smooth continuous movement
    infinite: true,            // Infinite loop for the carousel
    speed: 12000,               // Transition speed (9 seconds per slide)
    slidesToShow: 2,           // Number of slides to show at once
    slidesToScroll: 1,         // Scroll 1 slide at a time
    autoplay: true,            // Automatically scroll the slides
    autoplaySpeed: 0,          // With `cssEase: 'linear'`, this creates continuous movement
    cssEase: 'linear',         // Linear easing for smooth scrolling
    pauseOnHover: true,       // Do not pause the carousel on hover
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <NavBarMenu shouldLoadChatWidget={true} />
      <div className="homepage">

        {/* Hero Section */}
        <section className="hero-section">
          <div className="container text-center">
            <h1 className="hero-title">
              Elevate Your E-Commerce Experience with Effortless Protection
            </h1>
            <button className="btn btn-primary hero-button" onClick={handleViewProductsClick}>View Services</button>
          </div>
        </section>

        {/* Carousel for partner logos */}
        <Container  fluid style={{ paddingTop: '100px', paddingBottom: '100px', textAlign: 'center' }}>
          <section className="partners-section py-4">
              <div className="container text-center">
                <h2 className="partners-title">Our Partners</h2>
                <hr className="partners-divider" />
              </div>
              <Slider {...settings}>
                {partnerLogos.map((logo, index) => (
                  <div key={index} style={{ padding: '10px' }}>
                    <img src={logo.src} alt={logo.alt} className="partner-logo" style={{ maxWidth: '100%', margin: '0 auto' }} />
                  </div>
                ))}
              </Slider>
            </section>
        </Container>

        {/* Content Sections */}
        <section className="content-section py-5">
          <div ref={topContainerRef} className="container">
            {/* Top Section */}
            <div className="text-center mb-5">
              <h2 className="section-title">Efficiency and Innovation in One</h2>
              <p className="section-description">
                Unlock the future of online retail with Brella.ai’s AI-powered tools. Designed to enhance efficiency and
                customer engagement, our solutions help you navigate the complexities of e-commerce with ease.
                Experience the synergy of advanced analytics, personalized shopping, and automated customer service —
                crafted to propel your business towards unprecedented growth.
              </p>
            </div>

            {/* Image Left, Text Right */}
            <div className="row align-items-center mb-5">
              <div className="col-md-6">
                <img
                  src="working_people.png"
                  alt="Efficiency"
                  className="img-fluid content-image"
                />
              </div>
              <div className="col-md-6">
                <h3 className="content-title">Happy Shipments Await</h3>
                <p className="content-text">
                  Our Shipping Protection Widget ensures deliveries arrive safely and on time, boosting customer satisfaction
                  and reducing cart abandonment. Say goodbye to chargebacks and hello to increased revenue, supercharging
                  your store.
                </p>
                {/* <button className="btn btn-primary">View Products</button> */}
              </div>
            </div>

            {/* Image Right, Text Left */}
            <div className="row align-items-center">
              <div className="col-md-6 order-md-2">
                <img
                  src="Stock_1.png"
                  alt="Engage with Customers"
                  className="img-fluid content-image"
                />
              </div>
              <div className="col-md-6 order-md-1">
                <h3 className="content-title">Engage with Customers</h3>
                <p className="content-text">
                  Our Personalized Chatbot service provides 24/7 tailored support to your customers, helping them find answers,
                  resolve issues, and get personalized recommendations. Boost customer loyalty, reduce support queries, and
                  increase sales with a Chatbot that understands your customers’ unique needs.
                </p>
                {/* <button className="btn btn-primary">View Products</button> */}
              </div>
            </div>
          </div>
        </section>

        {/* Feature Highlights */}
        <section className="features-section text-center py-5 bg-dark text-light">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="feature-item">
                  <i className="feature-icon bi bi-lock"></i>
                  <h4 className="feature-title">Integrate and Protect Customer Purchases</h4>
                </div>
              </div>
              <div className="col-md-4">
                <div className="feature-item">
                  <i className="feature-icon bi bi-file-earmark-text"></i>
                  <h4 className="feature-title">Manage Claims with Peace of Mind</h4>
                </div>
              </div>
              <div className="col-md-4">
                <div className="feature-item">
                  <i className="feature-icon bi bi-check-circle"></i>
                  <h4 className="feature-title">Increase Revenue and Experience</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Supercharge Section */}
        <section className="supercharge-section text-center py-5">
          <div className="container position-relative d-flex justify-content-center">
            <div className="supercharge-content d-flex flex-column justify-content-center">
              <h2 className="supercharge-title">Supercharge Your Store and Your AOV</h2>
              <p className="supercharge-text">
                We put you in control with merchant-driven protection, making those clumsy third-party providers disappear.
                With Brella.ai, you’re not just protecting shipments; you’re performing a show-stopping act of business excellence.
              </p>
              <Link to="/ScheduleDemo" className="btn btn-primary supercharge-button">
                Schedule Demo
              </Link>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default Dashboard;
