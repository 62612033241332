// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 60%;
  }
  
  .message.user {
    align-items: flex-end;
    margin-left: auto;
  }
  
  .message.server {
    align-items: flex-start;
    margin-right: auto;
  }
  
  .message .role {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .message-text {
    padding: 10px;
    border-radius: 15px;
  }
  
  .message.user .message-text {
    background-color: #e1f5fe;
    border-bottom-right-radius: 0;
  }
  
  .message.server .message-text {
    background-color: #fff9c4;
    border-bottom-left-radius: 0;
  }
  
  .chat-window {
    border: 1px solid #ccc;
    padding: 10px;
    height: 80vh;
    overflow-y: scroll;
    background-color: #f9f9f9;
  }
  
  h3 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .list-group-item-custom {
    max-width: 100%;
    word-break: break-all;
    white-space: normal;
    padding: 10px;
    margin-bottom: 5px;
    overflow-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/ChatHistoryStyle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;EACnB;;EAEA;IACE,uBAAuB;IACvB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;IACzB,4BAA4B;EAC9B;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;AAC7B","sourcesContent":[".message {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 20px;\n    max-width: 60%;\n  }\n  \n  .message.user {\n    align-items: flex-end;\n    margin-left: auto;\n  }\n  \n  .message.server {\n    align-items: flex-start;\n    margin-right: auto;\n  }\n  \n  .message .role {\n    font-weight: bold;\n    margin-bottom: 5px;\n  }\n  \n  .message-text {\n    padding: 10px;\n    border-radius: 15px;\n  }\n  \n  .message.user .message-text {\n    background-color: #e1f5fe;\n    border-bottom-right-radius: 0;\n  }\n  \n  .message.server .message-text {\n    background-color: #fff9c4;\n    border-bottom-left-radius: 0;\n  }\n  \n  .chat-window {\n    border: 1px solid #ccc;\n    padding: 10px;\n    height: 80vh;\n    overflow-y: scroll;\n    background-color: #f9f9f9;\n  }\n  \n  h3 {\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  .list-group-item-custom {\n    max-width: 100%;\n    word-break: break-all;\n    white-space: normal;\n    padding: 10px;\n    margin-bottom: 5px;\n    overflow-wrap: break-word;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
