import React, { useState } from 'react';
import { ListGroup, Form, Button } from 'react-bootstrap';

const TrainedUrls = ({ trainedUrls, deleteTrainedUrls }) => {
    const [selectedUrls, setSelectedUrls] = useState({});
    const [selectAll, setSelectAll] = useState(false);

    const handleCheckboxChange = (url) => {
        setSelectedUrls(prev => ({
            ...prev,
            [url]: !prev[url]
        }));
    };

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        const newSelectedUrls = {};
        trainedUrls.forEach(url => {
            newSelectedUrls[url] = newSelectAll;
        });
        setSelectedUrls(newSelectedUrls);
    };

    const getSelectedUrls = () => {
        return Object.keys(selectedUrls).filter(url => selectedUrls[url]);
    };

    return (
        <div>
            <h3>Trained URLs</h3>
            {trainedUrls && trainedUrls.length > 0 ? (
                <>
                    <Form.Check
                        type="checkbox"
                        label="Select All"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                        className="mb-2"
                    />
                    <ListGroup>
                        {trainedUrls.map((url, index) => (
                            <ListGroup.Item className="list-group-item-custom" key={index}>
                                <Form.Check
                                    type="checkbox"
                                    label={url}
                                    checked={!!selectedUrls[url]}
                                    onChange={() => handleCheckboxChange(url)}
                                />
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </>
            ) : (
                <p>No trained URLs.</p>
            )}
            <Button variant="danger" onClick={() => deleteTrainedUrls(getSelectedUrls())} className="mt-2">
                Delete Selected URLs
            </Button>
        </div>
    );
};

export default TrainedUrls;
