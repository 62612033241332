import React from 'react';
import NavBarMenu from './NavbarMenu';
import { Container, Image, Row, Col, Button, Modal } from 'react-bootstrap';
import Footer from './Footer';

const Dashboard = () => {

    return (
        <>
            <NavBarMenu shouldLoadChatWidget={true} />
            <Container fluid style={{
                background: "linear-gradient(to right, #b0a6ff, #dff7ff)",
                paddingTop: '200px',
                paddingBottom: '200px',
                textAlign: 'center'
            }}>
                <Row className="justify-content-center align-items-center">
                    <Col lg={6} md={8}>
                        <h1>Increase Customer Satisfaction</h1>
                        <p>
                            Studies show that companies with chat connectivity experience a 30% increase in customer trust, leading to higher conversion rates and improved customer satisfaction.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container fluid style={{
                paddingTop: '100px',
                paddingBottom: '100px',
                textAlign: 'center'
            }}>
                <Row className="justify-content-center align-items-center">
                    <Col lg={6} md={8}>
                        <Row className="justify-content-center align-items-center">
                            <Col xs={12} md={6} lg={8} style={{ padding: '20px', maxWidth: '300px' }}>
                                <Image src="chatbot_connection.webp" alt="Image" fluid />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={8}>
                        <Row className="justify-content-center align-items-center">
                            <Col xs={12} md={6} lg={8}>
                                <h2>Meet Claimsworth: Your Store's Personal Butler</h2>
                                <p>Introducing Claimsworth, the butler you never knew you needed but now can’t live without. Think of him as your very own Jeeves, but for handling shipping claims.
Claimsworth is the epitome of sophistication and efficiency, taking care of your customers' shipping mishaps with the grace of a ballroom dancer and the precision of a Swiss watch. Lost packages, damaged goods, delayed deliveries? Claimsworth handles it all with a polite nod and a reassuring "Right away, sir/madam."</p><p>
Integration? It’s a breeze. Plug Claimsworth into your store, and watch as he seamlessly blends into your customer service team, ready to tackle any issue that comes his way. He’s always on duty, never takes a day off, and always dressed for success.
So, roll out the red carpet and let Claimsworth elevate your customer service game. Because when it comes to handling claims, he’s the butler of your dreams.
</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            {/* <Container fluid style={{
                paddingTop: '100px',
                paddingBottom: '100px',
                textAlign: 'center'
            }}>
                <Row className="justify-content-center align-items-center">
                    <Col lg={6} md={8} sm={12} xs={12} className="order-lg-1 order-md-2">
                        <Row className="justify-content-center align-items-center">
                        <Col xs={12} md={6} lg={8}>
                            <h2>Engage With Customers</h2>
                            <p>
                            Our Personalized Chatbot service provides 24/7 tailored support to your customers,
                                helping them find answers, resolve issues, and get personalized recommendations.
                                Boost customer loyalty, reduce support queries, and increase sales with a
                                chatbot that understands your customers' unique needs. Elevate your customer
                                experience today!
                            </p>
                        </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={8} sm={12} xs={12} className="order-lg-2 order-md-1">
                        <Row className="justify-content-center align-items-center">
                            <Col xs={12} md={6} lg={8} style={{ padding: '20px', maxWidth: '300px' }} className="justify-content-center align-items-center">
                                <Image src="https://d1f6rj4sne1926.cloudfront.net/package_being_protected.webp" alt="Image" fluid />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container> */}

            <Footer />
        </>
    );
};

export default Dashboard;
