import React, { useEffect } from 'react';
import NavBarMenu from './NavbarMenu';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Footer from './Footer';

const ScheduleDemo = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    script.onload = () => {
      console.log('Calendly script loaded');
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <NavBarMenu shouldLoadChatWidget={true} />
      <Container fluid style={{ paddingTop: '100px', paddingBottom: '100px', textAlign: 'center' }}>
        <Row className="justify-content-center align-items-center">
          <Col lg={6} md={8}>
            <div className="calendly-inline-widget" data-url="https://calendly.com/hayden-brella" style={{ minWidth: '320px', height: '630px' }}></div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ScheduleDemo;

