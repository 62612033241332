import React, { useEffect, useState } from 'react';
import { Table, Button, ListGroup, Modal, Spinner } from 'react-bootstrap';
import { fetchWithSessionToken } from './session_http_helper';

const PartnerList = () => {
  const [showCompanies, setShowCompanies] = useState(false);
  const [activePartnerId, setActivePartnerId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [partnerToDelete, setPartnerToDelete] = useState(null);
  const [partners, setPartners] = useState([]); // Initialize with an empty array
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const handleShowCompanies = async (partnerId) => {
    if (showCompanies && activePartnerId === partnerId) {
      setShowCompanies(false);
      return;
    }
  
    setActivePartnerId(partnerId);
  
    try {
      const response = await fetchWithSessionToken(`https://brella-protect-514098c560ec.herokuapp.com/partners/account_info?partnerId=${partnerId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });
  
      if (response.ok) {
        const data = await response.json();
        
        // Update the partner's companies in the state
        setPartners(prevPartners => prevPartners.map(partner => 
          partner.partnerId === partnerId ? { ...partner, companies: data.companies } : partner
        ));
  
        // Toggle the display of companies for this partner
        setShowCompanies(!showCompanies);
      }
    } catch (error) {
      console.error('Error fetching partner companies:', error);
    }
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = async () => {
    try {
      const response = await fetchWithSessionToken(`https://brella-protect-514098c560ec.herokuapp.com/partners`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data)) {
          const fetchedPartners = data.map(partner => ({
            ...partner,
            companyIds: partner.companyIds || []
          }));
          setPartners(fetchedPartners);
        }
      }
    } catch (error) {
      console.error('Error fetching partners:', error);
    } finally {
      setIsLoading(false); // Stop loading after fetching data
    }
  };

  const handleDeleteClick = (partnerId) => {
    setPartnerToDelete(partnerId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (!partnerToDelete) return;

    try {
      const response = await fetchWithSessionToken(`https://brella-protect-514098c560ec.herokuapp.com/partners?partnerId=${partnerToDelete}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.ok) {
        fetchPartners(); // Refresh the list after deletion
      }
    } catch (error) {
      console.error('Error deleting partner:', error);
    } finally {
      setShowDeleteModal(false);
      setPartnerToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setPartnerToDelete(null);
  };

  if (isLoading) {
    return (
      <div className="main-content" style={{ paddingTop: '100px', textAlign: 'center' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="main-content" style={{ paddingTop: '100px' }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Partner ID</th>
            <th>Companies</th>
            <th>Stripe Customer ID</th>
            <th>Accepted Agreement</th>
            <th>Address</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {partners.length > 0 ? (
            partners.map((partner) => (
              <React.Fragment key={partner.partnerId}>
                <tr>
                  <td>{partner.partnerId}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => handleShowCompanies(partner.partnerId)}
                    >
                      Show Companies
                    </Button>
                  </td>
                  <td>{partner.stripeCustomerId || 'N/A'}</td>
                  <td>{partner.acceptedAgreement ? 'Yes' : 'No'}</td>
                  <td>{partner.address || 'N/A'}</td>
                  <td>{partner.email}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteClick(partner.partnerId)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
                {showCompanies && activePartnerId === partner.partnerId && (
                  <tr>
                    <td colSpan="8">
                      <ListGroup>
                        {partner.companies && partner.companies.length > 0 ? partner.companies.map((company) => (
                          <ListGroup.Item key={company.companyId}>
                            {company.companyName} - Commission: {company.commission}%
                          </ListGroup.Item>
                        )) : (
                          <ListGroup.Item>No companies found</ListGroup.Item>
                        )}
                      </ListGroup>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="8" style={{ textAlign: 'center' }}>No partners found</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this partner?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PartnerList;
