import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Unset the local storage variable here
        localStorage.removeItem('loggedIn');

        navigate('/dashboard');
    }, []);

    return (
        <div>
        </div>
    );
};

export default Logout;
