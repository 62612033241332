import React from 'react';
import NavBarMenu from './NavbarMenu';
import { Container, Accordion, Card, Col, Button, Modal } from 'react-bootstrap';
import Footer from './Footer';

const FrequentlyAskedQuestions = () => {

    const faqData = [
        {
          question: "What is your return policy?",
          answer: "Our return policy is 30 days with no questions asked. You can return any item within 30 days of purchase for a full refund."
        },
        {
          question: "How long does shipping take?",
          answer: "Shipping usually takes 5-7 business days for standard shipping. Expedited shipping options are available at checkout."
        },
        {
          question: "Do you offer international shipping?",
          answer: "Yes, we offer international shipping to most countries. Shipping rates and times vary depending on the destination."
        }
      ];

    return (
        <>
      <NavBarMenu shouldLoadChatWidget={true} />
      <Container className="my-5" style={{
                paddingTop: '200px',
                background: "linear-gradient(to right, #ffffff, #f7f7f7)",
                paddingBottom: '200px',
                textAlign: 'center'
            }}>
        <h1>Frequently Asked Questions</h1>
        <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Question 1</Accordion.Header>
                <Accordion.Body>
                  <p>Go to the cart page, under subtotal click add block and add the app</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Question 2</Accordion.Header>
                <Accordion.Body>
                  <p>It should be added now</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Question 3</Accordion.Header>
                <Accordion.Body>
                  <p>You can now switch the widget to on and off.</p> 
                  <p>When switched to ON the product will be added to the cart when you press the checkout button</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Question 4</Accordion.Header>
                <Accordion.Body>
                  <p>After clicking checkout your product should be added to the cart</p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
      </Container>
      <Footer />
        </>
    );
};

export default FrequentlyAskedQuestions;
