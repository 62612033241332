import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#f8f9fa', paddingTop: '20px', paddingBottom: '20px' }}>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} md={6} lg={4}>
            <p style={{ textAlign: 'center' }}>© 2024 Brella.ai - All rights reserved.</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xs={6} md={4} lg={5} className="mb-3 mb-lg-0">
                <Link to="/TermsService" style={{ textDecoration: 'none', color: '#212529', textAlign: 'center', display: 'block' }}>Terms and Conditions</Link>
            </Col>
            <Col xs={6} md={4} lg={5}>
                <Link to="/PrivacyPolicy" style={{ textDecoration: 'none', color: '#212529', textAlign: 'center', display: 'block' }}>Privacy Policy</Link>
            </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
