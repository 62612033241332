import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import NavBar from './NavbarMenu';
import { Container, Row, Col, Image, Form, Button, Alert, Modal } from 'react-bootstrap';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CreateAccountPage() {
  const query = useQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [accountExistsError, setAccountExistsError] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [formData, setFormData] = useState({
    partnerId: '',
    firstName: '',
    lastName: '',
    userId: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [foundPartnerId, setFoundPartnerId] = useState(false);

  useEffect(() => {
    const partnerIdFromQuery = query.get('partnerId');
    if (partnerIdFromQuery) {
      setFormData(prevFormData => ({
        ...prevFormData,
        partnerId: partnerIdFromQuery
      }));
    }
  }, [query]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    if (e.target.id === 'password' || e.target.id === 'confirmPassword') {
      setPasswordsMatch(true);
    }
  };

  const validateInputs = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d+$/;

    if (!formData.firstName.trim()) errors.firstName = "First name is required";
    if (!formData.lastName.trim()) errors.lastName = "Last name is required";
    if (!formData.userId) {
      errors.userId = "Email is required";
    } else if (!emailRegex.test(formData.userId)) {
      errors.userId = "Invalid email format";
    }
    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else if (!phoneRegex.test(formData.phoneNumber)) {
      errors.phoneNumber = "Phone number must be numeric";
    }
    if (formData.password.length < 6) errors.password = "Password must be at least 6 characters";
    if (formData.password !== formData.confirmPassword) errors.confirmPassword = "Passwords do not match";

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handlePartnerIdSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setAccountExistsError(false);

    try {
      let checkResponse = await fetch(`https://brella-protect-514098c560ec.herokuapp.com/partners/account_info?partnerId=${formData.partnerId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (checkResponse.status === 200) {
        let response = await checkResponse.json();
        setFormData(prevFormData => ({
          ...prevFormData,
          userId: response.email
        }));
        setFoundPartnerId(true);
      } else {
        setFoundPartnerId(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleAgree = async () => {
    setShowModal(false);
    setAgreedToTerms(true);
    
    await handleAccountCreation({ preventDefault: () => {} });
  };

  const handleDisagree = () => {
    setShowModal(false);
    setAgreedToTerms(false);
  };

  const handleAccountCreation = async (e) => {
    e.preventDefault();

    if (!agreedToTerms) {
      setShowModal(true);
      return;
    }

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    const dataToSend = {
      companyId: formData.partnerId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      userId: formData.userId,
      phoneNumber: formData.phoneNumber,
      password: formData.password,
    };

    try {
      let checkResponse = await fetch(`https://brella-protect-514098c560ec.herokuapp.com/companies/${formData.partnerId}/users/${encodeURIComponent(formData.userId)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'bearer sdfjwei92131-23ejsndj2910-asdcjnfh'
        }
      });

      if (checkResponse.status === 404) {
        let checkResponseCreate = await fetch(`https://brella-protect-514098c560ec.herokuapp.com/companies/${formData.partnerId}/users/partners`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'bearer sdfjwei92131-23ejsndj2910-asdcjnfh'
          },
          body: JSON.stringify(dataToSend),
        });

        if (checkResponseCreate.status !== 201) {
          throw new Error(`Error: ${checkResponseCreate.status}`);
        }

        setIsLoading(false);
      } else {
        setAccountExistsError(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <NavBar shouldLoadChatWidget={false} />
      <div style={{ display: foundPartnerId ? 'none' : 'flex', justifyContent: 'center', paddingTop: '100px', alignItems: 'center', minHeight: '100vh' }}>
        <Container style={{ maxWidth: '1400px' }}>
          <Row className="justify-content-center mb-4">
            <Image src="brella_logo.png" fluid style={{ width: '200px', height: 'auto' }} />
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={5} xl={4} className="my-5 border rounded shadow p-5">
              <h1 className="display-4 text-center mb-3">Welcome!</h1>
              <Form onSubmit={handlePartnerIdSubmit}>
                <Form.Group className="mb-4" controlId="partnerId">
                  <Form.Control type="text" value={formData.partnerId} onChange={handleInputChange} placeholder="Enter your partner ID here" />
                </Form.Group>
                <Button className="btn-lg w-100 btn-primary mb-3" type="submit" disabled={isLoading}>
                  {isLoading ? 'Searching...' : 'Continue'}
                </Button>
                {accountExistsError && <Alert variant="danger">Partner ID not found.</Alert>}
                <div className="text-center">
                  <small className="text-body-secondary text-center">
                    Already have an account? <a href="/Login">Log in</a>.
                  </small>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal show={showModal} onHide={handleDisagree} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Brella Partnership/Referral Agreement</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <p><strong>This Partnership Agreement ("Agreement") is made and entered into by and between:</strong></p>
        <p><strong>Brella.ai</strong></p>
        <p><strong>And</strong></p>
        <p><strong>Partner</strong><br />
          {formData.firstName} {formData.lastName}<br />
          {formData.userId}<br />
          {formData.phoneNumber}
        </p>

        <p><strong>1. Term of Agreement</strong><br />This Agreement shall commence on the date of acceptance by both parties and shall continue in effect until terminated by either party in accordance with the terms of this Agreement.</p>

        <p><strong>2. Partnership Offerings</strong><br />Brella.ai agrees to provide the following offerings to the Partner in consideration for successful referrals:
        <ul>
          <li><strong>Referral Fee</strong>: Partner will receive 10% of Brella's earnings from the referred client in perpetuity for the first year.</li>
          <li><strong>Additional Incentives</strong>: For every 5 successful referrals within a calendar year, Partner will receive a bonus payment equivalent to 2 points of Brella's earnings from the newly referred and closed clients for an additional six months.</li>
        </ul>
        </p>

        <p><strong>3. Referral Process</strong><br />
        <ul>
          <li><strong>Submission</strong>: Partner must submit referral information through the designated form on Brella's website or via a secure link provided by Brella.</li>
          <li><strong>Tracking</strong>: Brella will provide a tracking ID for each referral to monitor the status and earnings associated with the referred client.</li>
        </ul>
        </p>

        <p><strong>4. Payment Terms</strong><br />
        <ul>
          <li><strong>Payment Schedule</strong>: Payments to the Partner will be made on a monthly basis. These payments will be made during the middle of each month.</li>
          <li><strong>Method of Payment</strong>: Payments will be issued via electronic transfer to the bank account provided by the Partner.</li>
          <li><strong>Reporting</strong>: Brella will provide detailed earnings reports to the Partner with each payment.</li>
          <li><strong>Payment Termination</strong>: If the referred client cancels or uninstalls Brella's services, the payment to the Partner will cease immediately on the date of cancellation/uninstallation.</li>
          <li><strong>Rainy Day Reserve Minimum</strong>: The Rainy Day Reserve must maintain a balance of more than $100 during the month for the Partner to receive referral incentives and payments.</li>
        </ul>
        </p>

        <p><strong>5. Responsibilities of Partner</strong>
        <ul>
          <li>Marketing: Partner agrees to promote Brella's services when they see fit through their channels and networks.</li>
          <li>Compliance: Partner must adhere to all applicable laws and regulations in promoting Brella's services.</li>
        </ul>
        </p>

        <p><strong>6. Confidentiality</strong><br />Both parties agree to keep all non-public information related to this Agreement confidential and not to disclose such information to any third party without prior written consent.
        </p>

        <p><strong>7. Termination</strong><br />
        <ul>
          <li><strong>Termination by Either Party</strong>: Either party may terminate this Agreement with 10 days' written notice.</li>
          <li><strong>Cause for Termination</strong>: Brella reserves the right to terminate this Agreement immediately if the Partner is found to be in breach of the terms or involved in activities detrimental to Brella's or the client's reputation or operations.</li>
        </ul>
        </p>

        <p><strong>8. Miscellaneous</strong><br />
        <ul>
          <li><strong>Modification</strong>: Any modifications to this Agreement must be in writing and signed by both parties.</li>
          <li><strong>Governing Law</strong>: This Agreement shall be governed by and construed in accordance with the laws of the jurisdiction in which Brella.ai operates.</li>
        </ul>
        </p>
        <p><strong>9. Acceptance</strong><br />By pressing agree below, both parties agree to the terms and conditions outlined in this Agreement.
        </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAgree}>Agree</Button>
          <Button variant="primary" onClick={handleDisagree}>Disagree</Button>
        </Modal.Footer>
      </Modal>

      <Container style={{ display: !foundPartnerId ? 'none' : 'block', paddingTop: '100px', maxWidth: '700px' }}>
          <Row className="justify-content-center mb-4">
              <Image src="brella_logo.png" fluid style={{ width: '200px', height: 'auto' }} />
          </Row>
          <Row className="justify-content-center">
              <Col xs={12} md={8} xl={6} className="my-5 border rounded shadow p-5">
                  <h1 className="display-4 text-center mb-3">Additional Info</h1>
                  <Form onSubmit={handleAccountCreation}>
                      <Form.Group className="mb-4" controlId="partnerId">
                          <Form.Label>Partner ID</Form.Label>
                          <Form.Control type="text" value={formData.partnerId} disabled placeholder="Partner Id" />
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="firstName">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control type="text" value={formData.firstName} onChange={handleInputChange} placeholder="First Name" />
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="lastName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control type="text" value={formData.lastName} onChange={handleInputChange} placeholder="Last Name" />
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="userId">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control type="email" value={formData.userId} onChange={handleInputChange} placeholder="name@address.com" />
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="phoneNumber">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control type="text" value={formData.phoneNumber} onChange={handleInputChange} placeholder="Phone Number" />
                          {validationErrors.phoneNumber && <Alert variant="danger">{validationErrors.phoneNumber}</Alert>}
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="password">
                          <Form.Label>Password</Form.Label>
                          <div className="input-group input-group-merge">
                              <Form.Control type={passwordVisible ? "text" : "password"} value={formData.password} onChange={handleInputChange} placeholder="Enter your password" />
                              <Button variant="outline-secondary" onClick={togglePasswordVisibility}>
                                  {passwordVisible ? 'Hide' : 'Show'}
                              </Button>
                          </div>
                          {validationErrors.password && <Alert variant="danger">{validationErrors.password}</Alert>}
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="confirmPassword">
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control type="password" value={formData.confirmPassword} onChange={handleInputChange} placeholder="Confirm Password" />
                          {validationErrors.confirmPassword && <Alert variant="danger">{validationErrors.confirmPassword}</Alert>}
                      </Form.Group>
                      <Button className="btn-lg w-100 btn-primary mb-3" type="submit" disabled={isLoading}>
                          {isLoading ? 'Signing up...' : 'Sign up'}
                      </Button>
                      {accountExistsError && <Alert variant="danger">Account already exists.</Alert>}
                      <div className="text-center">
                          <small className="text-body-secondary text-center">
                              Already have an account? <a href="/Login">Log in</a>.
                          </small>
                      </div>
                  </Form>
              </Col>
          </Row>
      </Container>
    </>
  );
}

export default CreateAccountPage;
