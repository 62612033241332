import React, { useState } from 'react';
import { Container, Row, Image, Col, Form, Button, Alert } from 'react-bootstrap';
import NavBar from './NavbarMenu';

function PasswordUpdate() {
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLoginError(false);

    try {
      const response = await fetch('https://brella-protect-514098c560ec.herokuapp.com/email/password/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: formData.email }),
      });

      if (!response.ok) {
        throw new Error('Failed to send password reset email');
      }

      setIsLoading(false);
      alert('Please check your email to reset your password.');
    } catch (error) {
      console.error('Password reset request failed:', error);
      setLoginError(true);
      setIsLoading(false);
    }
  };

  return (
    <>
        <NavBar shouldLoadChatWidget={false} />
        <Container className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "100vh", padding: "0 15px" }}>
            <Row className="justify-content-center mb-4">
                <Image src="brella_logo.png" fluid style={{ width: '200px', height: 'auto' }} />
            </Row>
            <Col xs={12} md={6} lg={4} className="my-5 border rounded shadow p-3">
                <h1 className="h3 mb-3 font-weight-normal text-center">Password Reset</h1>
                <p className="text-muted text-center mb-4">
                    Enter your email to get a password reset link.
                </p>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4" controlId="email">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="email" placeholder="name@address.com" value={formData.email} onChange={handleInputChange} className="form-control-lg"/>
                    </Form.Group>
                    <Button type="submit" className="btn btn-lg btn-primary w-100 mb-2" disabled={isLoading}>
                        {isLoading ? 'Sending email...' : 'Submit'}
                    </Button>
                    <div className="text-center mt-2">
                        <small>
                            Remember your password? <a href="/login" className="link-primary">Log in</a>.
                        </small>
                    </div>
                </Form>
            </Col>
        </Container>
    </>
  );
}

export default PasswordUpdate;
