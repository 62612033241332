import React, { useState, useEffect } from 'react';
import { ListGroup, Form, Button } from 'react-bootstrap';

const DiscoveredUrls = ({ discoveredUrls, handleTrainUrls, saveDiscoveredUrls, deleteDiscoveredUrls }) => {
    const [selectedUrls, setSelectedUrls] = useState({});
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        if (selectAll) {
            const newSelectedUrls = {};
            discoveredUrls.forEach(url => {
                newSelectedUrls[url] = true;
            });
            setSelectedUrls(newSelectedUrls);
        } else {
            setSelectedUrls({});
        }
    }, [selectAll, discoveredUrls]);

    const handleCheckboxChange = (url) => {
        setSelectedUrls(prev => ({
            ...prev,
            [url]: !prev[url]
        }));
    };

    const handleSelectAllChange = () => {
        setSelectAll(prevSelectAll => !prevSelectAll);
    };

    const getSelectedUrls = () => {
        return Object.keys(selectedUrls).filter(url => selectedUrls[url]);
    };

    return (
        <div>
            <h3>Discovered URLs</h3>
            {discoveredUrls && discoveredUrls.length > 0 ? (
                <>
                    <Form.Check
                        type="checkbox"
                        label="Select All"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                        className="mb-2"
                    />
                    <ListGroup>
                        {discoveredUrls.map((url, index) => (
                            <ListGroup.Item className="list-group-item-custom" key={index}>
                                <Form.Check
                                    type="checkbox"
                                    label={url}
                                    checked={!!selectedUrls[url]}
                                    onChange={() => handleCheckboxChange(url)}
                                />
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </>
            ) : (
                <p>No discovered URLs.</p>
            )}
            <Button variant="primary" onClick={() => handleTrainUrls(getSelectedUrls())} className="mt-2">
                Train Selected URLs
            </Button>
            <Button variant="danger" onClick={() => deleteDiscoveredUrls(getSelectedUrls())} className="mt-2">
                Delete Selected URLs
            </Button>
        </div>
    );
};

export default DiscoveredUrls;
